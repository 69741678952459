import { graphql } from 'gatsby'
import * as React from 'react'

import AboutUsPage, { AboutUsPageProps } from '../components/pages/AboutUsPage'
import { clientI18nEn } from '../language/clientEn'
import DefaultLayout from '../layouts'

const AboutUs = (props: AboutUsPageProps) => (
  <DefaultLayout language="en">
    <AboutUsPage {...props} i18n={clientI18nEn} otherLanguageUrl="/" />
  </DefaultLayout>
)

export default AboutUs

export const pageQuery = graphql`
  query AboutUsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
