import * as React from 'react'
import styled from 'styled-components'

import DefaultLayout from '../../layouts'
import { Footer } from '../Footer'
import { Nav } from '../Nav'
import { Section, SectionHeader } from '../Section'
import { SignUpBottomSection } from '../SignUpBottomSection'

export interface AboutUsPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

export interface AboutUsPagePropsWithi18n extends AboutUsPageProps {
  otherLanguageUrl: string
  i18n: {
    trustedBy: string
    howDoesSkilledWork: string | React.ReactNode
    step1: string
    step2: string
    step3: string
    joinSkilledCommunity?: string
    hireDeveloper: string
    applyAsDeveloper: string
    applyNow: string
    tagLine: string
    subtitle: string
    hire: string
    apply: string
    contact: string
    aboutUs: string
    blog: string
    otherLanguage: string
  }
}

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
`

const AboutUsPage = (props: AboutUsPagePropsWithi18n) => {
  return (
    <DefaultLayout language="en">
      <Nav
        i18n={props.i18n}
        languagePrefix="/"
        otherLanguageUrl={props.otherLanguageUrl}
        showClientLink
        showCandidateLink
      />

      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Section style={{ direction: 'ltr', flex: 1 }}>
          <SectionHeader>About Us</SectionHeader>

          <Paragraph>
            Skilled provides software development services. Each of our freelancers
            has gone through rigorous tests and background checks to ensure they're of the
            highest quality.
          </Paragraph>
        </Section>

        <SignUpBottomSection i18n={props.i18n} />
        <Footer i18n={props.i18n} />
      </div>
    </DefaultLayout>
  )
}

export default AboutUsPage
